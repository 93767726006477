var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pl-4 pr-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"contain":"","src":_vm.user.avatar_url
                  ? _vm.user.avatar_url
                  : require('@/assets/download.jpg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(`${_vm.user.title_name}`)+" ")])],1)],1)],1),_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form1"},[(_vm.user.employment)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Leave","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{staticClass:"rounded-0",attrs:{"error-messages":errors,"menu-props":{ down: true, offsetY: true },"outlined":"","label":"Leave","hide-details":"auto","items":_vm.getters_staff_leave.filter(
                      (item) =>
                        item.staff_category_id ===
                          _vm.user.employment.staff_position.staff_category_id &&
                        item.staff_division_id ===
                          _vm.user.employment.staff_position.staff_division_id
                    ),"item-value":"id","item-text":"leave"},on:{"change":_vm.leaveDetails},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item',[_vm._v(" Loading "),_c('strong',[_vm._v("Leave...")])])],1)]},proxy:true}],null,true),model:{value:(_vm.leave),callback:function ($$v) {_vm.leave=$$v},expression:"leave"}})]}}],null,false,3093584405)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"dobDialog",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Dates","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"error-messages":errors,"value":_vm.dateRangeText,"label":"Dates","readonly":"","disabled":!_vm.res,"hide-details":"auto","outlined":""}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,4049148329),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false;
                      _vm.dates = [];}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dobDialog.save(_vm.dates);
                      _vm.checkDuplicates(_vm.dates);}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Comments","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{staticClass:"rounded-0",attrs:{"error-messages":errors,"outlined":"","label":"Comments","placeholder":"A maximum of 500 characters","hide-details":"auto","counter":"500","rows":"12"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})]}}],null,false,3243640029)})],1)],1):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('v-card',{attrs:{"flat":"","tile":"","color":"blue lighten-5","min-height":"109%"}},[_c('v-toolbar',{staticClass:"caption",attrs:{"color":"blue lighten-3","elevation":"0"}},[_vm._v(" YOUR REQUEST ")]),_c('v-card-text',{staticClass:"px-0"},[_c('v-virtual-scroll',{attrs:{"bench":0,"items":_vm.enumDates,"height":"400","item-height":48},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-badge',{attrs:{"tile":"","icon":"mdi-alert-decagram-outline","transition":"slide-x-transition","content":_vm.overlappedLeave.includes(item)
                          ? 'Overlaps with existing leave.'
                          : null,"value":_vm.overlappedLeave.includes(item)
                          ? 'Overlaps with existing leave.'
                          : null,"color":"error"}},[_vm._v(" "+_vm._s(_vm.moment(item).format("Do MMMM"))+" ")])],1)],1),([0, 6].includes(_vm.moment(item).day()))?_c('v-list-item-action',[_c('span',{staticClass:"error--text"},[_vm._v(" Weekend ")])]):_c('v-list-item-action',[_c('div',{staticClass:"info--text"},[_vm._v("Working day")])])],1)]}}])})],1),_c('v-divider',{staticClass:"blue lighten-4"}),_c('v-card-actions',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(`${_vm.enumDates.length} ${ _vm.enumDates.length > 1 ? "days" : "day" } of Annual Leave`)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(`${ _vm.leaveSettings - _vm.approved > 1 ? _vm.leaveSettings - _vm.approved + ` days ` : _vm.leaveSettings - _vm.approved + ` day ` } remaining`)+" ")])],1)],1)],1),_c('v-footer',{attrs:{"app":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text",attrs:{"width":"230","type":"submit","color":"primary","disabled":_vm.passed},on:{"click":_vm.bookLeave}},[_vm._v(" APPLY ")])],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay,"opacity":".5"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"64"}})],1),(_vm.actionResponse)?_c('Response',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.msgHeader))]},proxy:true},{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.msgBody))]},proxy:true},{key:"icon",fn:function(){return [_vm._v(_vm._s(_vm.msgIcon))]},proxy:true}],null,false,916177833)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }