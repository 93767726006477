<template>
  <v-container fluid class="pl-4 pr-0">
    <v-row>
      <v-col cols="12" lg="6" class="pt-0">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                contain
                :src="
                  user.avatar_url
                    ? user.avatar_url
                    : require('@/assets/download.jpg')
                "
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ `${user.title_name}` }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <validation-observer ref="observer">
          <v-form ref="form1">
            <v-row v-if="user.employment">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Leave"
                  rules="required"
                >
                  <v-select
                    :error-messages="errors"
                    :menu-props="{ down: true, offsetY: true }"
                    outlined
                    class="rounded-0"
                    @change="leaveDetails"
                    label="Leave"
                    v-model="leave"
                    hide-details="auto"
                    :items="
                      getters_staff_leave.filter(
                        (item) =>
                          item.staff_category_id ===
                            user.employment.staff_position.staff_category_id &&
                          item.staff_division_id ===
                            user.employment.staff_position.staff_division_id
                      )
                    "
                    item-value="id"
                    item-text="leave"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item>
                          Loading
                          <strong>Leave...</strong>
                        </v-list-item>
                      </v-list-item>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  ref="dobDialog"
                  v-model="menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Dates"
                      rules="required"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :value="dateRangeText"
                        class="rounded-0"
                        label="Dates"
                        readonly
                        :disabled="!res"
                        hide-details="auto"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    :allowed-dates="allowedDates"
                    range
                    v-model="dates"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        menu = false;
                        dates = [];
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dobDialog.save(dates);
                        checkDuplicates(dates);
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Comments"
                  rules="required|max:500"
                >
                  <v-textarea
                    :error-messages="errors"
                    outlined
                    class="rounded-0"
                    label="Comments"
                    v-model="comment"
                    placeholder="A maximum of 500 characters"
                    hide-details="auto"
                    counter="500"
                    rows="12"
                  ></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
      <v-col cols="12" lg="6" class="pt-0">
        <v-card flat tile color="blue lighten-5" min-height="109%">
          <v-toolbar color="blue lighten-3" elevation="0" class="caption">
            YOUR REQUEST
          </v-toolbar>
          <v-card-text class="px-0">
            <v-virtual-scroll
              :bench="0"
              :items="enumDates"
              height="400"
              :item-height="48"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-badge
                        tile
                        icon="mdi-alert-decagram-outline"
                        transition="slide-x-transition"
                        :content="
                          overlappedLeave.includes(item)
                            ? 'Overlaps with existing leave.'
                            : null
                        "
                        :value="
                          overlappedLeave.includes(item)
                            ? 'Overlaps with existing leave.'
                            : null
                        "
                        color="error"
                      >
                        {{ moment(item).format("Do MMMM") }}
                      </v-badge>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action
                    v-if="[0, 6].includes(moment(item).day())"
                  >
                    <span class="error--text"> Weekend </span>
                  </v-list-item-action>

                  <v-list-item-action v-else>
                    <div class="info--text">Working day</div>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-divider class="blue lighten-4"></v-divider>
          <v-card-actions>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      `${enumDates.length} ${
                        enumDates.length > 1 ? "days" : "day"
                      } of Annual Leave`
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      `${
                        leaveSettings - approved > 1
                          ? leaveSettings - approved + ` days `
                          : leaveSettings - approved + ` day `
                      }  remaining`
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-footer app color="transparent">
              <v-spacer></v-spacer>

              <v-btn
                width="230"
                type="submit"
                color="primary"
                class="secondary--text"
                @click="bookLeave"
                :disabled="passed"
              >
                APPLY
              </v-btn>
            </v-footer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay absolute :value="overlay" opacity=".5">
      <v-progress-circular
        indeterminate
        color="white"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    inject,
    onMounted,
    provide,
    reactive,
    toRefs,
  } from "vue";

  import Api from "@/apis/Api";
  import Response from "@/components/ActionResponse/Response";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import moment from "moment";
  import {
    required,
    numeric,
    alpha_spaces,
    regex,
    digits,
    email,
    max,
  } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("email", {
    ...email,
    message: "Email must be valid",
  });

  extend("max", {
    ...max,
    //message: "Email must be valid",
  });

  extend("numeric", {
    ...numeric,
    message: "{_field_} should be numbers only",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  not a valid",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} can only accepts alphabets",
  });

  export default defineComponent({
    props: ["reset", "studentId"],
    components: { Response, ValidationProvider, ValidationObserver },
    setup(props, context) {
      const vm = getCurrentInstance();
      const { signOut, staffLeaveRequest, staffLeave } = useActions([
        "signOut",
        "staffLeaveRequest",
        "staffLeave",
      ]);
      const { getters_staff_leave, user, getters_staff_approved_leave } =
        useGetters([
          "getters_staff_leave",
          "user",
          "getters_staff_approved_leave",
        ]);

      const personal = reactive({
        observer: null,
        menu: false,
        dates: [],
        leave: "",
        comment: "",
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        color: null,
        overlay: false,
        actionResponse: false,
        res: null,
        form1: null,
        leaveSettings: 0,
        approved: 0,
        overlappedLeave: [],
        passed: false,
      });

      const {
        passed,
        overlappedLeave,
        form1,
        res,
        dates,
        leave,
        comment,
        observer,
        msgHeader,
        msgBody,
        msgIcon,
        color,
        overlay,
        actionResponse,
        leaveSettings,
        approved,
      } = toRefs(personal);

      const leaveSettingsAndApproved = inject("leaveSettingsAndApproved");

      onMounted(() => {
        leaveSettings.value = leaveSettingsAndApproved[0];
        approved.value = leaveSettingsAndApproved[1];
      });

      const dateRangeText = computed(() => dates.value.join(" - "));

      const enumDates = computed(() => {
        let startDate = dates.value[0];
        let endDate = dates.value[1];
        let date = [];

        if (res.value) {
          for (
            var m = moment(startDate);
            m.isBefore(endDate) || m.isSame(endDate);
            m.add(1, "days")
          ) {
            if (res.value.type_days == "working days") {
              if (![0, 6].includes(m.day())) date.push(m.format("YYYY-MM-DD"));
            } else date.push(m.format("YYYY-MM-DD"));
          }
        }

        return date;
      });

      const allowedDates = (val) => {
        if (
          res.value &&
          moment(val).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
        )
          return res.value.type_days == "working days"
            ? ![0, 6].includes(moment(val).day())
            : val;
      };

      provide("color", color);

      const leaveDetails = async () => {
        const data = {
          id: leave.value,
        };
        await Api()
          .post("/getstaffleavetype", { data })
          .then((result) => {
            res.value = result.data.data;
          })
          .catch((e) => {
            overlay.value = false;
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";

            if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            } else {
              msgBody.value = "Try Again Later or Call the System Administrator";
            }
          });
      };

      const bookLeave = () => {
        overlay.value = false;
        actionResponse.value = false;
        observer.value.validate().then((result) => {
          if (result) {
            overlay.value = true;

            staffLeaveRequest({
              leave_id: leave.value,
              days: enumDates.value.length,
              leave_dates: JSON.stringify(enumDates.value),
              comment: comment.value,
            })
              .then(() => {
                msgHeader.value = "Success";
                msgIcon.value = "mdi-close-circle";
                color.value = "success";
                msgBody.value = "Leave Is Succesfully Processed...";
                staffLeave({ status: "Pending" }).then(() =>
                  context.emit("pendingLeave", true)
                );
              })
              .finally(() => {
                overlay.value = false;
                actionResponse.value = true;
                observer.value.reset();
                form1.value.reset();
                dates.value = [];
              })
              .catch((e) => {
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
                color.value = "error";

                if (e.response.status === 423) {
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                } else {
                  msgBody.value =
                    "Try Again Later or Call the System Administrator";
                }
              });
          }
        });
      };

      const checkDuplicates = async () => {
        overlay.value = true;
        passed.value = false;
        await Api()
          .post("overlappingleave", enumDates.value)
          .then((res) => {
            overlappedLeave.value = res.data.data.flat();
            passed.value = enumDates.value.some((item) =>
              overlappedLeave.value.includes(item)
            );
            console.log(passed.value);
          })
          .catch(() => (passed.value = false))
          .finally(() => (overlay.value = false));
      };

      provide("color", color);

      return {
        ...toRefs(personal),
        dateRangeText,
        getters_staff_leave,
        enumDates,
        user,
        allowedDates,
        leaveDetails,
        bookLeave,
        getters_staff_approved_leave,
        checkDuplicates,
      };
    },
  });
</script>
