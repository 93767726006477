var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","disable-pagination":"","item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.user",fn:function({ value }){return [_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"contain":"","src":value.avatar_url
              ? value.avatar_url
              : require('@/assets/download.jpg')}})]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(value.title_name))])]}},{key:"item.leave_dates",fn:function({ item }){return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","rounded":"0","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',attrs,false),on),[_vm._v(" view dates ")])]}}],null,true)},[_c('v-sheet',{attrs:{"width":"600"}},[_c('v-toolbar',{attrs:{"elevation":"0","outlined":"","color":"secondary"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(" "+_vm._s(item.leave_dates.length)+" "+_vm._s(item.leave_dates.length > 1 ? "days" : "day")+" ")])],1),_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","event-color":_vm.getEventColor,"type":"month","events":item.leave_dates.map((item) => ({
                name: 'Leave',
                start: new Date(`${item}T00:00:00`),
                end: new Date(`${item}T23:59:59`),
                color: 'blue-grey darken-1',
              }))},on:{"change":_vm.getStaffLeaveStatus},model:{value:(_vm.leaveCalendar),callback:function ($$v) {_vm.leaveCalendar=$$v},expression:"leaveCalendar"}})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }