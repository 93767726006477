<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-toolbar color="primary" dark elevation="0">
            ANNUAL LEAVE
            <v-spacer></v-spacer>
            <v-btn color="white" @click="bookLeave" class="primary--text">
              Book Leave
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-skeleton-loader
              class="mx-auto"
              min-width="300"
              type="text"
              height="50"
              v-if="loading"
            ></v-skeleton-loader>
            <div v-else>
              <v-row justify="center" v-if="approved">
                <v-col cols="12">
                  <v-progress-linear
                    :value="(approved / leaveSettings) * 100"
                    color="info"
                    height="25"
                    class="mb-4 rounded-xl"
                  >
                  </v-progress-linear>
                  <div class="d-flex flex-row">
                    <div
                      :style="{
                        width: (approved / leaveSettings) * 100 + '%',
                      }"
                    >
                      <span class="body-1 font-weight-bold">
                        {{ approved }} </span
                      >days

                      <div class="body-2">approved</div>
                    </div>
                    <div
                      :style="{
                        width:
                          ((leaveSettings - approved) / leaveSettings) * 100 +
                          '%',
                      }"
                    >
                      <span class="body-1 font-weight-bold">
                        {{ leaveSettings - approved }}
                      </span>
                      {{ leaveSettings - approved > 0 ? "days" : "day" }}
                      <div class="body-2">remaining</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-progress-linear
                    :value="100"
                    color="info"
                    height="25"
                    class="mb-4 rounded-xl"
                  >
                  </v-progress-linear>
                  <div>
                    {{ `${leaveSettings} days remaining for Annual Leave` }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>MY LEAVE</v-toolbar-title>

          <template v-slot:extension>
            <v-tabs
              v-model="tab"
              align-with-title
              active-class="secondary--text"
            >
              <v-tabs-slider color="secondary"></v-tabs-slider>

              <v-tab v-for="status in leaveStatuses" :key="status">
                {{ status }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="status in leaveStatuses" :key="status">
            <v-card outlined tile>
              <my-staff-leave :key="componentKey" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="showComponent"
      fixed
      :permanent="showComponent"
      right
      class="shadow"
      clipped
      width="50%"
    >
      <v-toolbar class="mt-15" elevation="0">
        <v-btn
          left
          class="ma-2 primary--text"
          @click="showComponent = !showComponent"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-toolbar-title>STAFF LEAVE</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn class="ma-2 primary--text" icon @click="showComponent = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <component
        :is="componentCurrent"
        v-if="showComponent"
        @pendingLeave="displayPending"
      />
    </v-navigation-drawer>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import EditStaffLeave from "@/components/Leave/EditStaffLeave";
  import DeleteStaffLeave from "@/components/Leave/DeleteStaffLeave";
  import BookStaffLeave from "@/components/Leave/BookStaffLeave";
  import MyStaffLeave from "../../components/Leave/MyStaffLeave.vue";

  export default {
    components: {
      Response,
      BookStaffLeave,
      EditStaffLeave,
      DeleteStaffLeave,
      MyStaffLeave,
    },
    setup() {
      const vm = getCurrentInstance();
      const { signOut, getLeave, staffApprovedLeave } = useActions([
        "signOut",
        "getLeave",
        "staffApprovedLeave",
      ]);

      const { getters_staff_leave, getters_staff_approved_leave, user } =
        useGetters([
          "getters_staff_leave",
          "getters_staff_approved_leave",
          "user",
        ]);

      const leaveForm = reactive({
        approved: 0,
        showComponent: false,
        componentCurrent: null,
        response: false,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        loading: true,
        leaveSettings: 0,
        tab: null,
        leaveStatuses: ["Pending", "Approved", "Rejected"],
        statusSelected: "",
        componentKey: 0,
      });

      const {
        statusSelected,
        tab,
        approved,
        leaveSettings,
        showComponent,
        componentCurrent,
        loading,
        response,
        msgIcon,
        msgBody,
        msgHeader,
        color,
        componentKey,
      } = toRefs(leaveForm);

      const initLeave = () => {
        Promise.all([getLeave(), staffApprovedLeave()])
          .then(() => {
            loading.value = false;
            if (user.value?.employment) {
              let getData = getters_staff_leave.value.find(
                (item) =>
                  item.staff_category_id ===
                    user.value?.employment?.staff_position?.staff_category_id &&
                  item.staff_division_id ===
                    user.value?.employment?.staff_position?.staff_division_id
              );
              leaveSettings.value = getData.days;
              approved.value = [...getters_staff_approved_leave.value].reduce(
                (acc, obj) => acc + obj.leave_dates.length,
                0
              );
            }
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 403:
              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            loading.value = false;
          });
      };

      initLeave();

      provide("leaveSettingsAndApproved", [leaveSettings, approved]);

      const bookLeave = () => {
        componentCurrent.value = "BookStaffLeave";
        showComponent.value = true;
      };

      provide("leaveStatus", tab);

      watch(tab, (current) => {
        switch (current) {
          case 0:
            statusSelected.value = "Pending";
            break;

          case 1:
            statusSelected.value = "Approved";
            break;

          case 2:
            statusSelected.value = "Rejected";
            break;

          default:
            statusSelected.value = "Pending";
            break;
        }
      });

      provide("leaveStatus", statusSelected);

      const displayPending = (item) => {
        if (item) componentKey.value += 1;
      };

      return {
        ...toRefs(leaveForm),
        getters_staff_leave,
        bookLeave,
        getters_staff_approved_leave,
        displayPending,
      };
    },
  };
</script>
