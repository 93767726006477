<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :headers="headers"
      :items="results"
      hide-default-footer
      disable-pagination
      class="elevation-0"
      item-key="id"
      :loading="loading"
    >
      <template #item.user="{ value }">
        <v-avatar size="30">
          <img
            contain
            :src="
              value.avatar_url
                ? value.avatar_url
                : require('@/assets/download.jpg')
            "
          />
        </v-avatar>
        <span class="pl-4">{{ value.title_name }}</span>
      </template>
      <template #item.leave_dates="{ item }">
        <v-menu
          :close-on-content-click="false"
          offset-x
          rounded="0"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" depressed> view dates </v-btn>
          </template>
          <v-sheet width="600">
            <v-toolbar elevation="0" outlined color="secondary">
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.next()"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                {{ item.leave_dates.length }}
                {{ item.leave_dates.length > 1 ? "days" : "day" }}
              </v-toolbar-title>
            </v-toolbar>

            <v-calendar
              ref="calendar"
              color="primary"
              :event-color="getEventColor"
              v-model="leaveCalendar"
              type="month"
              :events="
                item.leave_dates.map((item) => ({
                  name: 'Leave',
                  start: new Date(`${item}T00:00:00`),
                  end: new Date(`${item}T23:59:59`),
                  color: 'blue-grey darken-1',
                }))
              "
              @change="getStaffLeaveStatus"
            ></v-calendar>
          </v-sheet>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { defineComponent, inject, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  //import Api from "@/apis/Api";

  export default defineComponent({
    setup() {
      const { staffLeave } = useActions(["staffLeave"]);
      const { getters_mystaff_leave } = useGetters(["getters_mystaff_leave"]);
      const leaveStatus = inject("leaveStatus");
      const data = reactive({
        results: [],
        loading: false,
        headers: [
          { text: "CALENDAR", value: "calendar.academic_calendar" },
          { text: "LEAVE STATUS", value: "status" },
          { text: "LEAVE DATES", value: "leave_dates" },
        ],
        leaveCalendar: "",
      });

      const { results, loading } = toRefs(data);

      const getStaffLeaveStatus = async () => {
        if (leaveStatus.value) {
          loading.value = true;
          staffLeave(leaveStatus.value).then(() => {
            results.value = getters_mystaff_leave.value;
            loading.value = false;
          });
        }
      };

      getStaffLeaveStatus();

      const getEventColor = (event) => event.color;

      return {
        ...toRefs(data),
        getEventColor,
        getStaffLeaveStatus,
      };
    },
  });
</script>

<style scoped>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>
